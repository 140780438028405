<template>
  <v-col cols="12">
    <v-card>
      <v-card-title>
        <h3>Reportes</h3>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="3" class="s-col-form">
            <v-radio-group row @change="changeTypeOption" v-model="obj.TypeOption" :disabled="obj.OptID > 0" :mandatory="true">
              <v-radio label="Reporte de Personal Critico por Cargo" :value="1"></v-radio>
              <v-radio label="Reporte Contigencia laborales" :value="2"></v-radio>
              <v-radio label="Reporte Amonestaciones" :value="3"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="1" class="s-col-form">
            <v-switch label="Todos" v-model="obj.Everybody" dense hide-details :false-value="0" :true-value="1" />
          </v-col>
          <v-col cols="3" class="s-col-form">
            <s-select-definition
              :def="1060"
              label="Planilla"
              v-model="obj.TypePayroll"
              :disabled="obj.Everybody == 1 ? true : false"
            ></s-select-definition>
          </v-col>

          <v-col cols="2" class="s-col-form" v-show="obj.TypeOption == 1">
            <s-select label="Filtro Críticos" v-model="obj.TypeCritict" :items="itemsCritic" />
          </v-col>
          <v-col cols="2" class="s-col-form" v-show="obj.TypeOption == 2 || obj.TypeOption == 3">
            <s-date v-model="obj.BeginDate" label="Fecha de Inicio" />
          </v-col>
          <v-col cols="2" class="s-col-form" v-show="obj.TypeOption == 2 || obj.TypeOption == 3">
            <s-date v-model="obj.EndDate" label="Fecha de Fin" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col cols="6" class="s-col-form">
              <v-btn @click="clearOption()" small text block color="danger"> <v-icon x-small left>fas fa-undo-alt</v-icon>Limpiar </v-btn>
            </v-col>
            <v-col cols="6" class="s-col-form">
              <v-btn @click="Generate()" small block color="primary "> <v-icon x-small left>fas fa-file-excel</v-icon>Generar Reporte </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-col>
</template>
<script>
import _sPosition from "@/services/General/PositionService";
export default {
  name: "Report",

  data: () => ({
    createTypeOption: 1,
    model: null,
    obj: {
      TypePayroll: 5,
      BeginDate: null,
      EndDate: null,
      TypeCritict: 0,
      Everybody: false,
      BeginDate: null,
      EndDate: null,
    },
    itemsCritic: [
      { text: "CRÍTICOS", value: 1 },
      { text: "NO CRÍTICOS", value: 0 },
      { text: "TODOS", value: 3 },
    ],
  }),
  watch: {
    model() {
      this.obj.tp = this.model;
    },
  },
  methods: {
    changeTypeOption() {
      this.createTypeOption = this.obj.TypeOption;
    },
    clearOption() {
      this.obj.TypeOption = 1;
      this.obj.Everybody = false;
    },
    isValidate(item) {
      let isValid;
      let message;
      console.log(item);
      if (item.typeoption == 1) {
        //----Reporte de cargos criticos
        if (item.everybody == 0) {
          // Seleccion por planilla
          isValid = item.typepayroll > 0;
          if (!isValid) message = "Seleccione Tipo de planilla";
          else {
            isValid = item.typecritict >= 0;
            if (!isValid) message = "Seleccione Tipo de Criticidad";
          }
        } else {
          isValid = item.typecritict >= 0;
          if (!isValid) message = "Seleccione Tipo de Criticidad";
        }
      }

      if (!isValid) this.$fun.alert(message, "warning");

      return isValid;
    },
    Generate() {
      var object = {
        typeoption: this.obj.TypeOption,
        everybody: this.obj.Everybody,
        typepayroll: this.obj.Everybody == 1 ? 0 : this.obj.TypePayroll,
        typecritict: this.obj.TypeCritict,
        begindate: this.obj.BeginDate,
        enddate: this.obj.EndDate,
        report:
          this.obj.TypeOption == 1
            ? "REPORTE_CARGOSCRITICOS"
            : this.obj.TypeOption == 2
            ? "REPORTE_CONTINGENCIASLABORALES"
            : "REPORTE_AMONESTACIONES",
      };
      _sPosition.report(object, this.$fun.getUserID()).then((r) => {
        this.$fun.downloadFile(r.data, this.$const.TypeFile.EXCEL, object.report);
      });
    },
  },
  created() {
    this.obj.EndDate = this.$moment().format("YYYY-MM-DD");
    this.obj.BeginDate = this.$moment().format("YYYY-MM-DD");
    this.obj.Everybody = false;
  },
};
</script>
